<template>
  <div>
    <table
      class="table table-striped- table-bordered table-hover no-footer"
      id="DataTables_Table_3"
    >
      <thead>
        <tr>
          <th class="align"><strong>#</strong></th>
          <th><strong>Job Details</strong></th>
          <th><strong>Customer Info</strong></th>
          <th><strong>Property</strong></th>
          <th><strong>Request For Bill</strong></th>
          <th><strong>Follow Up</strong></th>
          <th><strong>Status</strong></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="rowData.length > 0">
          <tr v-for="(row, index) in rowData" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="custlink">
              <strong> Delivery Id: </strong
              ><a href="#">
                <template v-if="row.visit">{{ row.visit.barcode }}</template></a
              ><br />

              <strong> Job Id: </strong
              ><a href="#">
                <template v-if="row.ticket">{{
                  row.ticket.barcode
                }}</template></a
              ><br />
              <strong> Job Title: </strong>
              <template v-if="row.ticket">{{ row.ticket.title }}</template>
              <br />
              <strong> Job Status: </strong>
              <v-tooltip top color="#000">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="closebtn ticketstatus"
                    ><template v-if="row.ticket">{{
                      row.ticket.status_text
                    }}</template></span
                  >
                </template>
                <span
                  ><template v-if="row.ticket">{{
                    row.ticket.status_text
                  }}</template></span
                >
              </v-tooltip>
            </td>

            <td>
              <strong> Company: </strong
              ><template v-if="row.customer">{{
                row.customer.company_name
              }}</template
              ><br />
              <strong> Display Name: </strong
              ><template v-if="row.customer">{{
                row.customer.display_name
              }}</template
              ><br />
              <strong> Email: </strong>
              <template v-if="row.customer && row.customer.default_person">{{
                row.customer.default_person.primary_email
              }}</template>
              <br />

              <!-- <strong> Address: </strong> 
              <template v-if="row.customer && row.customer.billing">{{
                getAddress(row.customer.billing)
              }}</template> -->

              <strong> Phone: </strong>
              <template v-if="row.customer && row.customer.default_person">{{
                row.customer.default_person.primary_phone
              }}</template>
            </td>
            <td>
              <template v-if="row.property">{{
                getAddress(row.property)
              }}</template>
            </td>
            <td>
              <div>
                <span>{{
                  row.request_for_bill && row.request_for_bill.remark
                }}</span>
              </div>
              <div class="font-weight-bold">
                <template v-if="row && row.followup"> &nbsp; </template>
              </div>
              <v-tooltip top color="#000" v-if="row.request_for_bill">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    v-on:click.prevent="getFollowupRemarks(row, 'bill')"
                    ><span class="viewbtn ticketstatus">view</span></a
                  >
                </template>
                <span>View Request For Bill Remark history</span>
              </v-tooltip>
            </td>
            <td>
              <span>{{ row.followup.remark }}</span>
              <div style="font-weight: 600">
                <template v-if="row && row.followup">
                  {{ formatDate(row.followup.date) }}
                </template>
              </div>
              <div>
                <v-tooltip top color="#000">
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-on:click.prevent="getFollowupRemarks(row, 'followup')"
                      ><span class="viewbtn ticketstatus">view</span></a
                    >
                  </template>
                  <span>view follow up history</span>
                </v-tooltip>
              </div>
            </td>

            <td>
              <div>
                <v-tooltip top color="#000">
                  <template v-slot:activator="{ on, attrs }"
                    ><span
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="openbtn ticketstatus"
                      >{{ row.status_text }}</span
                    >
                  </template>
                  <span>{{ row.status_text }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="row.status != 2"
                class="btn-group btn-block btn-group-sm"
              >
                <v-tooltip top color="#000">
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="btn btn-primary updatebtn"
                      v-on:click="updateRemark(row)"
                      ><strong>Update</strong></a
                    >
                  </template>
                  <span>update status</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no Follow-Ups at the moment.
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <v-col md="12" class="col-12 pb-0 pt-0">
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
    </v-col>
    <!-- <dialogcomp></dialogcomp> -->
    <Dialog :commonDialog="searchDialogupdate">
      <template v-slot:title>Follow-Up Remark History</template>
      <template v-slot:body>
        <v-form
          ref="remarkForm"
          lazy-validation
          v-on:submit.stop.prevent="submitRemark"
        >
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 90vh; position: relative"
          >
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <div class="col-lg-2 cust-text">
                  <h5>Job #</h5>
                </div>
                <div class="col-lg-4 cust-text">
                  <h5>
                    <template v-if="followUpData && followUpData.ticket">{{
                      followUpData.ticket.barcode
                    }}</template>
                  </h5>
                </div>
                <div class="col-lg-2 cust-text">
                  <h5>Customer</h5>
                </div>
                <div class="col-lg-4 cust-text">
                  <h5>
                    <template v-if="followUpData && followUpData.customer">{{
                      followUpData.customer.display_name
                    }}</template>
                  </h5>
                </div>
                <div class="col-lg-2 cust-text">
                  <h5>Job Title</h5>
                </div>
                <div class="col-lg-4 cust-text">
                  <h5>
                    <template v-if="followUpData && followUpData.ticket">{{
                      followUpData.ticket.title
                    }}</template>
                  </h5>
                </div>
                <div class="col-lg-2 cust-text">
                  <h5>Address</h5>
                </div>
                <div class="col-lg-4 cust-text">
                  <h5>
                    <template
                      v-if="
                        followUpData.customer && followUpData.customer.billing
                      "
                      >{{ getAddress(followUpData.customer.billing) }}</template
                    >
                  </h5>
                </div>
                <v-radio-group
                  v-model="remarkCreate.status"
                  row
                  :rules="[
                    validateRules.required(
                      remarkCreate.status,
                      'Follow-Up Status',
                      1
                    ),
                  ]"
                  class="w-100"
                >
                  <v-col cols="6">
                    <v-radio label=" Mark as Open " value="1"></v-radio>
                  </v-col>

                  <v-col cols="6">
                    <v-radio label=" Mark as Complete" value="2"></v-radio>
                  </v-col>
                </v-radio-group>
                <v-radio-group
                  v-model="remarkCreate.type"
                  row
                  :rules="[
                    validateRules.required(
                      remarkCreate.status,
                      'Follow-Up Status',
                      1
                    ),
                  ]"
                  class="w-100"
                >
                  <v-col cols="6">
                    <v-radio label=" Request For Bill " value="0"></v-radio>
                  </v-col>
                  <v-col cols="6">
                    <v-radio label=" Follow Up" value="1"></v-radio>
                  </v-col>
                </v-radio-group>

                <v-date-picker
                  v-model="remarkCreate.date"
                  elevation="15"
                  no-title
                ></v-date-picker>
                <v-textarea
                  outlined
                  name="input-7-4"
                  :label="
                    remarkCreate.type == 0
                      ? 'Request For Bill'
                      : remarkCreate.type == 1
                      ? 'Follow Up Remark'
                      : 'Remark'
                  "
                  v-model="remarkCreate.remark"
                  rows="15"
                ></v-textarea>
              </v-row>
            </v-container>
          </perfect-scrollbar>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="submitRemark"
        >
          Submit
        </v-btn>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="searchDialogupdate = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="remarksViewDialog">
      <template v-slot:title
        >{{
          request_type == "followup" ? "Follow-up" : "Request For Bill"
        }}
        remark history</template
      >
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <v-container fluid class="pt-0 custom-search-filter">
            <v-row>
              <div class="col-lg-2 cust-text">
                <h5>Job #</h5>
              </div>
              <div class="col-lg-4 cust-text">
                <h5>
                  <template v-if="followUpData && followUpData.ticket">{{
                    followUpData.ticket.barcode
                  }}</template>
                </h5>
              </div>
              <div class="col-lg-2 cust-text">
                <h5>Customer</h5>
              </div>
              <div class="col-lg-4 cust-text">
                <h5>
                  <template v-if="followUpData && followUpData.customer">{{
                    followUpData.customer.display_name
                  }}</template>
                </h5>
              </div>
              <div class="col-lg-2 cust-text">
                <h5>Job Title</h5>
              </div>
              <div class="col-lg-4 cust-text">
                <h5>
                  <template v-if="followUpData && followUpData.ticket">{{
                    followUpData.ticket.title
                  }}</template>
                </h5>
              </div>
              <div class="col-lg-2 cust-text">
                <h5>Address</h5>
              </div>
              <div class="col-lg-4 cust-text">
                <h5>
                  <template
                    v-if="
                      followUpData.customer && followUpData.customer.billing
                    "
                    >{{ getAddress(followUpData.customer.billing) }}</template
                  >
                </h5>
              </div>
              <div class="form-row">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 20%" v-if="request_type == 'followup'">
                        Follow up date
                      </th>
                      <th style="width: 60%">Remark</th>
                      <th style="width: 20%">Type</th>
                      <th style="width: 20%">Created Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="followUpRemarks.length > 0">
                      <tr v-for="(row, index) in followUpRemarks" :key="index">
                        <td
                          class="font-weight-bold"
                          v-if="request_type == 'followup'"
                        >
                          {{ formatedDate(row.date) }}
                        </td>
                        <td>{{ row.remark }}</td>
                        <td v-if="row.type == 1">Follow Up</td>
                        <td v-else>Request For Bill</td>

                        <td>
                          <TableActivity
                            v-if="!lodash.isEmpty(row.added_by)"
                            :data="row"
                          >
                            <template v-slot:display_name>
                              {{ row.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ row.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(row.added_at) }}
                            </template>
                          </TableActivity>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="3">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no Remarks at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="remarksViewDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  QUERY,
  CLEAR_ERROR,
  POST,
  GET,
} from "@/core/services/store/request.module";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "Tabledata",
  mixins: [CommonMixin, ValidationMixin],
  components: {
    Dialog,
    ListingFooter,
    TableActivity,
  },
  data() {
    return {
      tab: null,
      searchDialogupdate: false,
      remarksViewDialog: false,
      column: null,
      formLoading: false,
      row: null,
      request_type: null,
      remarkCreate: {
        date: "",
        remark: null,
        status: "1",
        follow_up_id: null,
        type: "1",
      },
      rowData: [],
      totalPages: null,
      currentPage: null,
      totalRows: null,
      rowsOffset: null,
      followUpData: {},
      followUpRemarks: [],
    };
  },

  props: {
    type: {
      type: String,
      default: "all",
    },
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    updateRemark(data) {
      this.followUpData = data;
      this.remarkCreate.follow_up_id = data.id;
      this.searchDialogupdate = true;
    },
    getFollowUpList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "follow-up",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getFollowupRemarksList(folowupId, type) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "follow-up-remarks/" + folowupId + "/" + type,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getFollowups() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      let filterData = {
        current_page,
        per_page,
        status: this.type,
        search: _this.lodash.toString(_this.filter_search) || null,
        daterange: _this.dates,
        contractor: _this.filter_contractor,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
      };
      _this
        .getFollowUpList(filterData)
        .then((data) => {
          _this.rowData = data.rows;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.dataLoading = false;
          _this.$emit("statusList", data.status_list);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getFollowupRemarks(followup, type) {
      const _this = this;

      _this.request_type = type;
      if (_this.pageLoading) {
        return false;
      }
      _this.pageLoading = true;
      _this
        .getFollowupRemarksList(followup.id, type)
        .then((data) => {
          _this.followUpRemarks = data;
          this.followUpData = followup;
          _this.remarksViewDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getAddress(address) {
      let addressArr = [];
      if (address.unit) {
        addressArr.push(address.unit);
      }
      if (address.street_1) {
        addressArr.push(address.street_1);
      }
      if (address.street_2) {
        addressArr.push(address.street_2);
      }
      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }
      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }
      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }
      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }
      return addressArr.join(", ");
    },
    submitRemark() {
      const _this = this;
      if (!_this.$refs.remarkForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "follow-up-remark";

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.remarkCreate })
        .then(async (response) => {
          _this.productId = response.data ? response.data.id : null;
          _this.remarkCreate = {
            date: null,
            remark: null,
            status: "1",
            follow_up_id: null,
            type: "1",
          };
          _this.searchDialogupdate = false;
          _this.getFollowups();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    this.getFollowups();
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.without(this.rowData, undefined);
      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
  },
};
</script>
